import './hero.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProductCard2 from 'Components/ProductCard/ProductCard2';
import banner1 from 'assets/banner-1.webp';
import banner2 from 'assets/banner-2.webp';
import banner3 from 'assets/banner-3.webp';
import banner4 from 'assets/banner-4.webp';
import { useTranslation } from 'react-i18next';

const products = [
  {
    slider_id: 1,
    slider_image: banner1,
  },
  {
    slider_id: 2,
    slider_image: banner2,
  },
  {
    slider_id: 3,
    slider_image: banner3,
  },
  {
    slider_id: 5,
    slider_image: banner4,
  },
];

const HeroSection = () => {
  const { t } = useTranslation();

  var heroSlider = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: 'linear',
    arrows: false,
  };

  return (
    <section className="heroSectionOuter">
      <section className="heroSection">
        <div className="heroSliderContainer">
          <div className="infoContainer">
            <h1>{t('heading1')}</h1>
            <h3>{t('heading3')}</h3>
          </div>
        </div>
      </section>
      <div className="heroSliderOuter">
        <Slider {...heroSlider}>
          {products?.map((product) => (
            <ProductCard2
              key={product?.slider_id}
              img={product?.slider_image}
            />
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default HeroSection;
