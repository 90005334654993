import ShowProductDetail from 'Components/ProductDetail/ShowProductDetail';
import BuyerLayout from 'layouts/BuyerLayout';

export default function ShowProductDetailPage() {
  return (
    <BuyerLayout>
      <ShowProductDetail />
    </BuyerLayout>
  );
}
