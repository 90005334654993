import './showcase.scss';
import showCase1 from 'assets/showcase-1.webp';
import showCase2 from 'assets/showcase-2.webp';
import showCase3 from 'assets/showcase-3.webp';
import showCase4 from 'assets/showcase-4.webp';
import showCase5 from 'assets/showcase-5.webp';
import ShowCaseImg from './ShowCaseImg';

const ShowCaseSection = () => {
  return (
    <section className="showcaseSection">
      <div className="showcase">
        <div className="showcase__left">
          <ShowCaseImg img={showCase1} alt="showcase" />
        </div>
        <div className="showcase__right">
          {/* top */}
          <div className="showcase__right__top">
            <div className="showcase__right__top__left">
              <ShowCaseImg img={showCase2} alt="showcase" />
            </div>
            <div className="showcase__right__top__right">
              <ShowCaseImg img={showCase3} alt="showcase" />
            </div>
          </div>
          {/* bottom */}
          <div className="showcase__right__bottom">
            <div className="showcase__right__bottom__left">
              <ShowCaseImg img={showCase4} alt="showcase" />
            </div>
            <div className="showcase__right__bottom__right">
              <ShowCaseImg img={showCase5} alt="showcase" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShowCaseSection;
