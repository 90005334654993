import './relevantCard.scss';
import { Link } from 'react-router-dom';

export default function RelevantCard({
  img,
  title,
  product_id,
  product_settings_id,
}) {
  return (
    <div className="relevantCardOuter">
      <Link to={`/featureProduct/${product_id}/${product_settings_id}`}>
        <div className="featureProductImg">
          <img src={img} alt="featured product image" />
          <div className="infoContainer">
            <div className="producTitle">{title}</div>
            <div className="productButton">
              <button className="moreButton">View Details</button>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export function MostSearchedCard({ img, title, product_id, product }) {
  return (
    <div className="relevantCardOuter">
      <Link to={`/showProductDetail/${product_id}`}>
        <div className="featureProductImg">
          <img src={img} alt="featured product image" />
          <div className="infoContainer2">
            <div className="producTitle">{title}</div>
            <div className="producTitleInfoOuter">
              <div className="producTitleInfo">
                <i className="ri-shopping-cart-fill"></i> Stock In :{' '}
                <span>{product?.total_qty} Q</span>
              </div>
              <div className="producTitleInfo ml-7">
                <i className="ri-map-pin-fill"></i> Locations :{' '}
                <span>{product?.total_locations}</span>
              </div>
            </div>
            <div className="viewMore">
              <button className="viewMoreButton">View Sellers</button>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
