import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import './login.scss';
import { forwardRef, useEffect, useState } from 'react';
import qs from 'qs';
import axios from 'hooks/axios';
import { Button, TextField } from '@mui/material';
import DeliveryAddress from './DeliveryAddress';
import ListOfAddress from './ListOfAddress';
import { toastMessageWarning } from 'utils/toast';
import SelectListAddress from './SelectListAddress';

const API_URL = process.env.REACT_APP_BACKEND_URL;
const token = localStorage.getItem('token');
const authToken = JSON.parse(token);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function SelectAddressModal({
  handleAddressListClose,
  addressListOpen,
  setDeliveryAdress,
  deliveryAddress,
  addressDetails,
  setAddressDetails,
  ConfirmDeliveryChange,
  setCallbackAddress,
}) {
  const userInfo = localStorage.getItem('userInfo');
  const user = JSON.parse(userInfo);
  const zip = JSON.parse(localStorage.getItem('zipCode'));
  const [zipCode, setZipCode] = useState('');

  // const [addressDetails, setAddressDetails] = useState([]);
  const [editName, setEditName] = useState('');
  const [addressOpen, setAddressOpen] = useState(false);
  const [address, setAdress] = useState({
    buyer_id: user?.buyer_id,
    buyer_name: `${user?.first_name} ${user?.last_name}`,
    address_label: '',
    buyer_short_address: '',
    buyer_address_1: '',
    buyer_address_2: '',
    buyer_mobile: user?.buyer_mobile,
    buyer_email: user?.buyer_email,
    buyer_country: '101',
    buyer_state: '',
    buyer_city: '',
    buyer_zip: '',
    shiping_info_id: '',
  });

  const handleAddressClose = () => {
    setAddressOpen(false);
    setAdress({
      buyer_id: user?.buyer_id,
      buyer_name: `${user?.first_name} ${user?.last_name}`,
      address_label: '',
      buyer_short_address: '',
      buyer_address_1: '',
      buyer_address_2: '',
      buyer_mobile: user?.buyer_mobile,
      buyer_email: user?.buyer_email,
      buyer_country: '101',
      buyer_state: '',
      buyer_city: '',
      buyer_zip: '',
      shiping_info_id: '',
    });
  };

  // buyer signUp dialouge
  const handleAddressOpen = () => {
    if (authToken) {
      setEditName('Add');
      setAddressOpen(true);
    } else {
      toastMessageWarning({ message: 'Please login to save address details' });
    }
  };

  const pincodeChecker = /^[1-9][0-9]{5}$/;

  const handleCheckPincode = async () => {
    if (zipCode !== '' && pincodeChecker.test(zipCode) !== false) {
      // localStorage.removeItem('deliveryAddress');
      const address = {
        address_label: '',
        buyer_address_1: '',
        buyer_address_2: '',
        buyer_city: '',
        buyer_country: '101',
        buyer_email: user?.buyer_email,
        buyer_id: user?.buyer_id,
        buyer_mobile: user?.buyer_mobile,
        buyer_name: `${user?.first_name} ${user?.last_name}`,
        buyer_short_address: '',
        buyer_state: '',
        buyer_zip: zipCode,
        city_name: '',
        country_name: '',
        created_by: '',
        created_on: '',
        first_name: '',
        last_name: '',
        order_id: '',
        seller_id: '',
        shiping_info_id: '',
        state_name: '',
        status: '',
        updated_by: '',
        updated_on: '',
      };
      // setDeliveryAdress(address);
      // setCallbackAddress(address);
      setDeliveryAdress((prevAds) => {
        const newAddress = address;
        setCallbackAddress(newAddress);
        ConfirmDeliveryChange(newAddress);
        return newAddress;
      });
      localStorage.setItem('deliveryAddress', JSON.stringify(address));
      localStorage.setItem('zipCode', JSON.stringify(zipCode));
      // ConfirmDeliveryChange();
      handleAddressListClose();
    } else {
      if (zipCode === '') {
        return toastMessageWarning({
          message: 'Pincode Number Cannot be empty',
        });
      } else if (pincodeChecker.test(zipCode) === false) {
        return toastMessageWarning({
          message: `Please Add A Valid 6 Digit Pincode !`,
        });
      }
    }
  };

  useEffect(() => {
    if (zip) {
      setZipCode(zip);
    }
  }, []);

  return (
    <>
      <Dialog
        open={addressListOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleAddressListClose}
        fullWidth="md"
        maxWidth="md"
        style={{
          borderRadius: '20px',
        }}
      >
        <div className="modal-body p-0">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={handleAddressListClose}
          >
            <span aria-hidden={true}>×</span>
          </button>
          <div className="loginContainer">
            <div className="flex items-center justify-between mt-3">
              <div className="modal-header">
                <div className="loginTitle">Choose Your Location</div>
              </div>
            </div>
            <hr />
            <p className="font-medium text-sm mb-2">
              Select a delivery location to see product availability and
              delivery options.
            </p>
            <Button
              // style={{ marginTop: '1rem' }}
              variant="contained"
              size="small"
              color="success"
              onClick={handleAddressOpen}
            >
              Add New Delivery Address
            </Button>

            {addressDetails?.length > 0 ? (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
                {addressDetails?.map((address) => (
                  <SelectListAddress
                    key={address?.shiping_info_id}
                    address={address}
                    deliveryAddress={deliveryAddress}
                    setDeliveryAdress={setDeliveryAdress}
                    handleAddressListClose={handleAddressListClose}
                    ConfirmDeliveryChange={ConfirmDeliveryChange}
                    setCallbackAddress={setCallbackAddress}
                  />
                ))}
              </div>
            ) : (
              <h2
                style={{
                  textAlign: 'center',
                  fontSize: '1.25rem',
                  padding: '1rem 0',
                }}
              >
                No Address List Found. Please add one !
              </h2>
            )}

            <h6 className="lineRow">
              <span>OR</span>
            </h6>

            <div className="enterPincodeContainer">
              <TextField
                id="locationPin"
                name="locationPin"
                // type="number"
                min={0}
                inputProps={{
                  maxLength: 10,
                  type: 'number',
                }}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, e.target.maxLength);
                }}
                onWheel={(e) => e.target.blur()}
                onKeyDown={(evt) =>
                  ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
                }
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
                className="textField w-2/4 mr-4"
                placeholder="Enter Your Pincode Here"
              />
              <button
                onClick={handleCheckPincode}
                className="mb-2 md:mb-0 bg-green-700  w-2/4  border-green-700 px-5 py-3 text-sm shadow-sm font-medium tracking-wider text-white  hover:shadow-lg hover:bg-green-700"
              >
                Check Pincode
              </button>
            </div>
          </div>
        </div>
      </Dialog>
      {addressOpen && (
        <DeliveryAddress
          handleAddressClose={handleAddressClose}
          setAddressDetails={setAddressDetails}
          adressOpen={addressOpen}
          address={address}
          setAdress={setAdress}
          editName={editName}
        />
      )}
    </>
  );
}
